import { Space, Tag, Typography } from 'antd';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { FC } from 'react';
import { PostItemInfo } from '../PostItemInfo';
import { TContent } from '../PostItemInfo/PostItemInfo.props';
import styles from './BlogCardItem.module.scss';
import { BlogCardItemProps } from './BlogCardItem.props';

const { Title, Paragraph } = Typography;

const BlogCardItem: FC<BlogCardItemProps> = ({ item }) => {
  const imgSrc = item?.attributes?.featuredImage?.data?.attributes?.url;
  const imgAlt = item?.attributes?.featuredImage?.data?.attributes?.alternativeText;

  return (
    <Link href={`/${item?.attributes?.slug}`} className={styles.card}>
      <div className={styles.cover}>
        <Image src={imgSrc ? imgSrc : ''} alt={imgAlt ? imgAlt : ''} width={370} height={208} layout="responsive" />
        <span className={styles.blogBtn}>blog</span>
      </div>

      <div className={styles.content}>
        <Space>
          {!!item?.attributes?.post_categories?.data?.length &&
            item.attributes.post_categories.data.slice(0, 3).map((btn) => (
              <Tag key={btn.id} className={styles.categoryTag}>
                {btn.attributes?.name}
              </Tag>
            ))}
        </Space>

        <Title level={3} ellipsis={{ rows: 2 }} className={styles.title}>
          {item?.attributes?.title}
        </Title>

        <Paragraph ellipsis={{ rows: 3 }} className={styles.subtitle}>
          {item?.attributes?.excerpt}
        </Paragraph>

        <PostItemInfo
          publishDate={item?.attributes?.publishDate}
          content={item?.attributes?.content as TContent[]}
          showPublishDate={item?.attributes?.showPublishDate}
          readingTime={item?.attributes?.reading_time}
        />
      </div>
    </Link>
  );
};

export { BlogCardItem };
