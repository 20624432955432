import dayjs from 'dayjs';

export type TDisabledTime = {
  disabledHours: () => number[];
  disabledMinutes: () => number[]
} | {};

export const disabledFutureDate = (current: dayjs.Dayjs) => current > dayjs();

export const disabledBeforeTime = (current: dayjs.Dayjs | null, startValue: dayjs.Dayjs):TDisabledTime => {
  const start = dayjs(startValue);
  const minHours = start.hour() + (start.minute() === 59 ? 1 : 0);
  const minMinutes = start.minute() === 59 ? 0 : start.minute() + 1;

  const momentCurrent = dayjs(current);

  return start.isSame(momentCurrent, 'day') ? {
    disabledHours: () => Array.from({ length: minHours }, (_e, hour) => hour),
    disabledMinutes: () => (start.isSame(momentCurrent, 'hour')
      ? Array.from({ length: minMinutes }, (_e, minute) => minute) : []),
  } : {};
};

export const disabledFutureTime = (current: dayjs.Dayjs | null):TDisabledTime => {
  const start = dayjs();
  const maxHours = start.hour() + (start.minute() === 59 ? 1 : 0);

  const maxMinutes = start.minute() === 59 ? 0 : start.minute() + 1;

  const momentCurrent = dayjs(current);
  const lengthHours = 24 - maxHours;
  const lengthMinutes = 60 - maxMinutes;

  return start.isSame(momentCurrent, 'day') ? {
    disabledHours: () => Array.from({ length: lengthHours }, (_e, k) => maxHours + k + 1),
    disabledMinutes: () => (start.isSame(momentCurrent, 'hour')
      ? Array.from({ length: lengthMinutes }, (_e, k) => maxMinutes + k + 1) : []),
  } : {};
};

export const disabledBeforeDate = (current: dayjs.Dayjs, startValue: dayjs.Dayjs) => {
  const start = dayjs(startValue, 'YYYY-MM-DD')
    .add(1,"minute").startOf('day');

  return current < start;
};
