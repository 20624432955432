import { CapacityInput, EngineInput, FieldInput, GetMakers, GetTripDetails, InputMaybe } from 'services/graphql';
import { GetBoatDetailsData } from 'services/types';
import { momentToDate } from './dates';
import { EditableCustomFields } from 'services/types/fields';

/** Dynamic Inputs */

type InputFieldType = {
  name: string;
  value?: string | null;
}[];

export const sendFieldInput = (item?: InputFieldType): Array<FieldInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    // @ts-ignore: Ignore error, works correctly.
    return item?.filter((field) => !(!field?.name || (!field?.value && field?.value !== 0)));
  }

  return [];
};

export const getFieldInputOrDefault = (item?: InputFieldType): Array<FieldInput> => {
  if (item && item.length) {
    return item?.map((field) => ({
      name: field?.name || '',
      value: field?.value,
    }));
  }

  return [{ name: '', value: undefined }];
};

type InputCapacityType = {
  capacity: number;
}[];

export const sendCapacityInput = (item?: InputCapacityType): Array<CapacityInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    // @ts-ignore: Ignore error, works correctly.
    return item?.filter((field) => !!field?.capacity || field?.capacity === 0);
  }

  return undefined;
};

export const getCapacityInputOrDefault = (item?: InputCapacityType): Array<{ capacity?: number }> => {
  if (item && item.length) {
    return item?.map((field) => ({
      capacity: field?.capacity || 0,
    }));
  }

  return [{ capacity: undefined }];
};

export const engineInputDefaultValues = {
  drive: undefined,
  fuel: undefined,
  maker: undefined,
  model: undefined,
  power: undefined,
  power_type: 'hp',
  type: undefined,
  website: undefined,
};

type InputEngineType = {
  maker?: string;
  website?: string;
  model?: string;
  type?: string;
  drive?: string;
  fuel?: string;
  power?: string;
  power_type?: string;
}[];

export const getEngineInputToBeSaved = (
  item: InputMaybe<EngineInput | EngineInput[]>,
): Array<EngineInput> | undefined => {
  if (item && Array.isArray(item) && item.length) {
    return item?.filter((engine) => {
      if (engine) {
        if (
          engine.drive ||
          engine.fuel ||
          engine.maker ||
          engine.model ||
          engine.power ||
          engine.type ||
          engine.website
        ) {
          return true;
        }
      }

      return false;
    });
  }

  return [];
};

export const getEngineInputOrDefault = (item?: InputEngineType): Array<EngineInput> => {
  if (item && item.length) {
    return item?.map((field) => ({
      ...field,
      power_type: field?.power_type ? field?.power_type : 'hp',
    }));
  }

  // Default values
  return [engineInputDefaultValues];
};

export const getStorageInput = (storage?: Array<{ capacity?: number }>): { capacity: number }[] => {
  if (storage?.length) {
    return storage
      .map((item) => ({ capacity: item.capacity || -1 }))
      .filter((item) => (item.capacity || item.capacity === 0) && item.capacity >= 0);
  }

  return [];
};

export const getFieldInput = (fields: InputMaybe<FieldInput | FieldInput[]>): FieldInput[] => {
  if (fields) {
    if (Array.isArray(fields) && fields.length) {
      return fields.filter((item) => item.name && item.name.length && item.value && item.value.length);
    }

    if (!Array.isArray(fields)) {
      if (fields.name && fields.name.length && fields.value && fields.value.length) {
        return [{ name: fields.name, value: fields.value }];
      }
    }
  }

  return [];
};

/** Maker */

export const getSelectedMaker = (makersData?: GetMakers, selectedMaker?: string | null): string | undefined => {
  if (makersData?.manufacturers?.data?.length && selectedMaker) {
    return makersData?.manufacturers?.data.find((e) => e.name === selectedMaker)?.id;
  }

  return undefined;
};

/** Categories/Activities */

type GetInputIds = {
  name?: string;
  id: string;
}[];

export const getStringIds = (items?: GetInputIds): string[] | undefined => {
  if (items && Array.isArray(items) && items.length) {
    return items.map((item) => item?.id!);
  }

  return undefined;
};

export const sendArrayOfNumbers = (items?: string[] | null): number[] | undefined => {
  if (items && Array.isArray(items) && items.length) {
    return items.map((item) => parseInt(item!, 10));
  }

  return undefined;
};

/** Files */

export const sendFileIds = (files?: GetBoatDetailsData['files']): number[] | undefined => {
  if (files && Array.isArray(files) && files.length) {
    return files?.filter((file) => !!file?.id)?.map((file) => parseInt(file?.id!, 10));
  }

  return undefined;
};

type FiledInput = InputMaybe<FieldInput[]> | undefined;

export const getCustomFields = (fields?: EditableCustomFields[]): FiledInput => {
  if (fields && fields?.length) {
    return fields.map((item) => {
      if (item) {
        if (item.type === 'date') {
          return { name: item?.name || '', value: momentToDate(item?.value) || '' };
        }

        return { name: item?.name || '', value: item?.value?.toString() || '' };
      }

      return { name: '', value: '' };
    });
  }

  return [];
};

/** String/Number or Undefined */

export const toFloatOrUndefined = (field?: string | null) => {
  if (field) {
    return parseFloat(field);
  }

  return undefined;
};

export const toIntOrUndefined = (field?: string | null) => {
  if (field) {
    return parseInt(field, 10);
  }

  return undefined;
};

// Utils for People Pickers
export type LabelInValue = {
  label: string | JSX.Element | (string | JSX.Element)[];
  value: string;
};

type MembersLabelInValueReturnType = LabelInValue[] | undefined;

type MembersLabelInValueItems = NonNullable<GetTripDetails['trip']>['members'];

export const getMembersLabelInValue = (items?: MembersLabelInValueItems): MembersLabelInValueReturnType => {
  if (!!items && items?.length) {
    return items.map((item) => ({
      label: item?.display_name || '',
      value: item?.email || '',
    }));
  }

  return undefined;
};

// Utils for Location Picker

export const getLocationValue = (
  destination_latitude: number | undefined,
  destination_longitude: number | undefined,
) => {
  if (destination_latitude && destination_longitude) {
    return {
      lat: destination_latitude,
      lng: destination_longitude,
    };
  }

  return undefined;
};
