import { FC } from 'react';
import { Input } from 'antd';
import { useRouter } from 'next/router';
import styles from './BlogJumbotron.module.scss';
import { BlogJumbotronProps } from './BlogJumbotron.props';
import { Jumbtron } from '../../Jumbtron';

const BlogJumbotron: FC<BlogJumbotronProps> = ({ title, isFilter, description, searchResultNr }) => {
  const router = useRouter();
  const { q } = router.query;

  const handleSearch = (value: string) => {
    if (value.trim().length > 0) {
      router.push(`/search?q=${value.trim()}`);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.jumbotron}>
          {isFilter ? (
            <Jumbtron title={'Search Result'} subtitle={`${searchResultNr} Search results for "${q}"`} />
          ) : (
            <Jumbtron title={title} subtitle={description} />
          )}
        </div>

        <div className={styles.containerSearch}>
          <Input.Search
            placeholder="Search in blog, news, persona..."
            onSearch={(value) => handleSearch(value)}
            className={styles.search}
            allowClear
          />
        </div>
      </div>
    </section>
  );
};

export { BlogJumbotron };
