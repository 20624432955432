import { Modal } from 'antd';
import React, { FC, useEffect, useRef } from 'react';
import styles from './VideoModal.module.scss';

export type VideoModalState = 'NOT_OPENED' | 'CLOSED' | 'OPEN';
interface Props {
  isModalVisible: VideoModalState;
  closeVideoModal: () => void;
  src: string;
}

const VideoModal: FC<Props> = ({ isModalVisible, closeVideoModal, src }) => {
  const iframe = useRef<HTMLIFrameElement>();
  const handleOkVideoModal = (): void => {
    closeVideoModal();
  };

  const handleCancelVideoModaL = (): void => {
    closeVideoModal();
  };

  useEffect(() => {
    if (isModalVisible === 'CLOSED' && iframe) {
      iframe?.current?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    } else if (isModalVisible === 'OPEN') {
      iframe.current?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
  }, [isModalVisible]);

  return (
    <Modal
      width="90vw"
      open={isModalVisible === 'OPEN'}
      onOk={handleOkVideoModal}
      onCancel={handleCancelVideoModaL}
      closable={false}
      footer={null}
      centered
      className={styles.videoModal}
    >
      <iframe
        title="Video"
        className="VideoModal-Iframe"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        height={450}
        ref={iframe}
      />
    </Modal>
  );
};

export { VideoModal };
