import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

const useReferralToken = () => {
  const [cookies] = useCookies(['rewardful.referral']);
  const referralCookie = cookies['rewardful.referral'];

  const params = useMemo<{ via?:string }>(() => {
    if (typeof window !== 'undefined') {
      return new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
      });
    }

    return {};
  }, []);

  const referralToken = useMemo(() => {
    if (referralCookie?.affiliate?.token) {
      return referralCookie?.affiliate?.token;
    }

    if (params?.via) {
      return params?.via;
    }

    return null;
  }, [referralCookie, params]);

  return {
    referralToken,
  };
};

export { useReferralToken };
