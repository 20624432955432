import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';

extend(utc);

export const yearToDayjs = (year?: number | null) => {
  if (year) {
    return dayjs(year.toString());
  }

  return undefined;
};

export const momentToYear = (yearMoment?: dayjs.Dayjs) => {
  if (yearMoment?.isValid()) {
    return yearMoment?.year();
  }

  return undefined;
};

export const dateToDayjs = (date?: string | null) => {
  if (date) {
    return dayjs(date);
  }

  return undefined;
};

export const momentPostPublishDate = (date: string) => dayjs(date).format('MMM DD, YYYY');

export const momentToDate = (dateMoment?: dayjs.Dayjs | string | null): string | undefined => {
  if (dateMoment) {
    if (typeof dateMoment === 'string') {
      return dateMoment;
    }

    return dayjs(dateMoment).format('YYYY-MM-DD');
  }

  return undefined;
};

type MomentToStringOrUndefined<TValue> = TValue extends dayjs.Dayjs ? string : undefined;

export const momentToDateTime = <TValue extends dayjs.Dayjs | undefined>(
  dateTimeMoment: TValue,
  timeFormat?: string,
  convertToUTC = true,
): MomentToStringOrUndefined<TValue> => {
  if (dateTimeMoment) {
    if (convertToUTC) {
      return dayjs
        .utc(dateTimeMoment)
        .format(`YYYY-MM-DD ${timeFormat || 'HH:mm'}`) as MomentToStringOrUndefined<TValue>;
    }

    return dayjs(dateTimeMoment).format(`YYYY-MM-DD ${timeFormat || 'HH:mm'}`) as MomentToStringOrUndefined<TValue>;
  }

  return undefined as MomentToStringOrUndefined<TValue>;
};

export const momentToDateTimeWithMS = <TValue extends dayjs.Dayjs | undefined>(
  dateTimeMoment: TValue,
  timeFormat?: string,
  convertToUTC = true,
): MomentToStringOrUndefined<TValue> => {
  if (dateTimeMoment) {
    if (convertToUTC) {
      return dayjs
        .utc(dateTimeMoment)
        .format(`YYYY-MM-DD ${timeFormat || 'HH:mm:ss.SSS'}`) as MomentToStringOrUndefined<TValue>;
    }

    return dayjs(dateTimeMoment).format(
      `YYYY-MM-DD ${timeFormat || 'HH:mm:ss.SSS'}`,
    ) as MomentToStringOrUndefined<TValue>;
  }

  return undefined as MomentToStringOrUndefined<TValue>;
};

export const momentToDateTimeWithSec = <TValue extends dayjs.Dayjs | undefined>(
  dateTimeMoment: TValue,
  timeFormat?: string,
  convertToUTC = true,
): MomentToStringOrUndefined<TValue> => {
  if (dateTimeMoment) {
    if (convertToUTC) {
      return dayjs
        .utc(dateTimeMoment)
        .format(`YYYY-MM-DD ${timeFormat || 'HH:mm:ss'}`) as MomentToStringOrUndefined<TValue>;
    }

    return dayjs(dateTimeMoment).format(`YYYY-MM-DD ${timeFormat || 'HH:mm:ss'}`) as MomentToStringOrUndefined<TValue>;
  }

  return undefined as MomentToStringOrUndefined<TValue>;
};
