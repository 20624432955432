import { Breadcrumb } from 'antd';
import Link from 'next/link';
import { FC } from 'react';
import styles from './BlogBreadcrumb.module.scss';
import { BlogBreadcrumbProps } from './BlogBreadcrumb.props';

const BlogBreadcrumb: FC<BlogBreadcrumbProps> = ({ breadcrumbs }) => (
  <div className={styles.wrap}>
    <div className={styles.breadcrumbWrap}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link className={styles.link} href="/">
            Home
          </Link>
        </Breadcrumb.Item>
        {breadcrumbs?.map((item) => (
          <Breadcrumb.Item key={item.path}>
            <Link className={styles.link} href={item.path}>
              {item.name}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  </div>
);

export { BlogBreadcrumb };
