import { useMemo } from 'react';
import { GetTripDetails } from 'services/graphql';

interface TripPermissions{
  canShare:boolean
  canDelete:boolean
  canEdit:boolean
  hasEnd:boolean
}

export function useTripPermissions(trip?:GetTripDetails['trip']):TripPermissions {
  return useMemo<TripPermissions>(() => {
    if (!trip) {
      return {
        canDelete: false,
        canShare: false,
        canEdit: false,
        hasEnd: false,
      };
    }

    return {
      canDelete: !!trip.can_delete,
      canShare: !!trip?.can_share,
      canEdit: !!(trip?.can_update),
      hasEnd: !!trip?.finished_at,
    };
  }, [trip]);
}
