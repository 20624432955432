import { FC, useMemo } from 'react';
import { usePostsCategories } from 'services';
import { useRecentPosts } from 'services/hooks/queries/useRecentPosts';
import styles from './Sidebar.module.scss';
import { SidebarProps } from './Sidebar.props';
import { allPostCategoriesVariables } from 'services/queryClient/defaultQueryVariables';
import { SidebarSection } from './components/SidebarSection';
import { Empty, Space, Tag, Typography } from 'antd';
import Link from 'next/link';
import Image from 'next/image';

const counterKey = new Map([
  ['TBD-WEB', 'tbdPostsCount'],
  ['TBA-WEB', 'tbaPostsCount'],
]);

const { Title } = Typography;

const Sidebar: FC<SidebarProps> = ({ appCode }) => {
  const { data: allCategories } = usePostsCategories(allPostCategoriesVariables);
  const { data: recentPostsData } = useRecentPosts({ appCode, pageSize: 5 });

  const filteredCategories = useMemo(() => {
    const key = counterKey.get(appCode) as 'tbaPostsCount' | 'tbdPostsCount';

    if (key) {
      if (allCategories?.postCategories?.data.length) {
        return allCategories?.postCategories?.data?.filter((item) => {
          if (item?.attributes?.[key]) {
            return item?.attributes?.[key] > 0;
          }

          return false;
        });
      }
    }

    return [];
  }, [appCode, allCategories?.postCategories?.data]);

  return (
    <aside>
      <SidebarSection title="Categories">
        {filteredCategories.length ? (
          filteredCategories.map((btn: any) => (
            <Space key={btn.attributes.slug} size={[8, 16]} wrap>
              <Link href={`/category/${btn.attributes.slug}`}>
                <Tag className={styles.categoryTag}>{btn.attributes.name}</Tag>
              </Link>
            </Space>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No categories for this post" />
        )}
      </SidebarSection>
      <SidebarSection title="Recent posts">
        {recentPostsData?.posts?.data.length ? (
          recentPostsData?.posts?.data?.map((post) => (
            <Link className={styles.itemRecentPost} key={post.id} href={`/${post.attributes?.slug}`}>
              <Space key={post.attributes?.slug} size={8}>
                <Image
                  width={64}
                  height={64}
                  src={post?.attributes?.featuredImage?.data?.attributes?.url || ''}
                  alt={
                    post.attributes?.featuredImage?.data?.attributes?.alternativeText || post?.attributes?.title || ''
                  }
                />

                <Title level={3} ellipsis={{ rows: 2 }}>
                  {post?.attributes?.title}
                </Title>
              </Space>
            </Link>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No recent posts" />
        )}
      </SidebarSection>
    </aside>
  );
};

export { Sidebar };
