import { FC } from 'react';
import { Tag, Typography } from 'antd';
import Image from 'next/image';
import styles from './PostItemListCard.module.scss';
import { PostItemListCardProps } from './PostItemListCard.props';
import { PostItemInfo } from '../PostItemInfo';
import { TContent } from '../PostItemInfo/PostItemInfo.props';
import cn from 'classnames';
const { Title, Paragraph } = Typography;

const PostItemListCard: FC<PostItemListCardProps> = ({ post, isFeaturedPost = false }) => {
  const featuredImageUrl = post?.attributes?.featuredImage?.data?.attributes?.url;
  const featuredImageALt = post?.attributes?.featuredImage?.data?.attributes?.alternativeText;

  return (
    <section className={cn(styles.wrapper, { [styles.featuredPost]: isFeaturedPost })}>
      <div className={styles.cover}>
        <Image
          src={featuredImageUrl ? featuredImageUrl : ''}
          width={274}
          height={274}
          alt={featuredImageALt ? featuredImageALt : ''}
        />
      </div>

      <div className={styles.dataWrapper}>
        <div className={styles.head}>
          {!!post?.attributes?.post_categories?.data.length && (
            <div className={styles.categories}>
              {post?.attributes?.post_categories?.data.map((category) => (
                <Tag key={category.id} className={styles.tag}>
                  {category.attributes?.name}
                </Tag>
              ))}
            </div>
          )}

          <Title level={2} ellipsis={{ rows: 2 }} className={styles.title}>
            {post?.attributes?.title}
          </Title>

          <Paragraph ellipsis={{ rows: 3 }} className={styles.subtitle}>
            {post?.attributes?.excerpt}
          </Paragraph>
        </div>

        <PostItemInfo
          content={post?.attributes?.content as TContent[]}
          publishDate={post?.attributes?.publishDate}
          showPublishDate={post?.attributes?.showPublishDate}
          readingTime={post?.attributes?.reading_time}
        />
      </div>
    </section>
  );
};

export { PostItemListCard };
