import { FC } from 'react';
import styles from './SidebarSection.module.scss';
import { SidebarSectionProps } from './SidebarSection.props';

const SidebarSection: FC<SidebarSectionProps> = ({ children, title }) => (
  <div className={styles.sidebarSectionWrapper}>
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

export { SidebarSection };
