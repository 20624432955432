import { useEffect, useRef, useState } from 'react';

export function usePrint<T>(data: T[]) {
  const [isReady, setIsReady] = useState(false);

  const exportFnRef = useRef<(documentTitle: string) => void>(() => {});

  useEffect(() => {
    Promise.all([import('jspdf'), import('jspdf-autotable')]).then(([{ jsPDF: JsPDF }]) => {
      setIsReady(true);
      exportFnRef.current = (documentTitle) => {
        const doc = new JsPDF({ orientation: 'landscape', unit: 'pt' });

        doc.setProperties({ title: documentTitle });

        if (data && data.length) {
          // @ts-ignore
          const keys = Object.keys(data[0]) as Array<keyof (typeof data)[0]>;
        }

        // const res = doc.autoTableHtmlToJson(document.getElementsByTagName('table')[0]);
        // const columnsTable = res.columns.slice(0, res.columns.length - 1);
        // const dataTable = res.data.map((x) => {
        //   x = x.slice(0, x.length - 1);
        //   x[0].content = x[0].content.replace('Details', '');

        //   x.map((y) => {
        //     if (y.content == '' && !y._element.classList.contains('ant-table-cell')) {
        //       y.content = y._element.children[0].getElementsByTagName('input')[0].value;
        //     }

        //     return y;
        //   });

        //   return x;
        // });

        // doc.autoTable(columnsTable, dataTable, { margin: { top: 80 } });
        // window.open(doc.output('bloburl', { filename: documentTitle }), '_blank');
      };
    });
  }, [data]);

  return {
    handlePdfExport: exportFnRef.current,
    isReady,
  };
}
