import { useMemo } from 'react';
import { convertTimeMinutesText, convertTimeToMinutes, readWords, stripTags, stripWhitespace } from '../reading';

export function useReadingTime(text: string) {
  const strippedString = useMemo(() => stripTags(stripWhitespace(text)), [text]);
  const { time, words } = useMemo(() => readWords(strippedString), [strippedString]);

  return {
    text: convertTimeMinutesText(time),
    minutes: convertTimeToMinutes(time),
    words,
    time,
  };
}
