import Link from 'next/link';
import { FC, useCallback } from 'react';
import { NewsItemEntity, ResponseCollectionMeta } from 'services/graphql/cms';
import styles from './NewsList.module.scss';
import Image from 'next/image';
import { Row, Typography, Col, Pagination } from 'antd';
import { GetNewsCardData, GetNewsCardItem } from 'services';
import { useRouter } from 'next/router';

const { Title, Paragraph } = Typography;

interface NewsListProps {
  news: GetNewsCardItem[];
  meta: ResponseCollectionMeta;
}

const NewsCard: FC<GetNewsCardItem> = ({ attributes }) => {
  const imgUrl = attributes?.featuredImage?.data?.attributes?.url || '';
  const alt = attributes?.featuredImage?.data?.attributes?.alternativeText || attributes?.title || '';

  return (
    <div className={styles.card}>
      <Image src={imgUrl} alt={alt} width={320} height={300} />
      <div className={styles.body}>
        <Title level={2} ellipsis={{ rows: 1 }} className={styles.title}>
          {attributes?.title}
        </Title>
        <div className={styles.descriptionWrapper}>
          <div className={styles.description}>
            <Paragraph ellipsis={{ rows: 3 }}>{attributes?.excerpt}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsList: FC<NewsListProps> = ({ news, meta }) => {
  const router = useRouter();
  const handleOnPageChange = useCallback(
    (page: number) => {
      router.push({ query: { ...router.query, page } });
    },
    [router],
  );

  return (
    <div className={styles.newsListWrapper}>
      <div className={styles.newsList}>
        <Row gutter={[24, 32]}>
          {news.length &&
            news.map((newsItem: GetNewsCardItem) => (
              <Col
                key={newsItem.id}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
              >
                <Link href={`news/${newsItem.attributes?.slug}`}>
                  <NewsCard {...newsItem} />
                </Link>
              </Col>
            ))}
        </Row>
      </div>
      <Pagination
        onChange={handleOnPageChange}
        pageSize={meta?.pagination.pageSize}
        total={meta?.pagination?.total || 10}
        current={meta?.pagination?.page || 1}
        responsive
      />
    </div>
  );
};

export { NewsList };
