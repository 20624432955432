import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useKeenSlider } from 'keen-slider/react';
import { FC, useState } from 'react';
import { BlogCardItem } from '../BlogCardItem';
import styles from './SimilarPosts.module.scss';
import { SimilarPostsProps } from './SimilarPosts.props';

const SimilarPosts: FC<SimilarPostsProps> = ({ posts }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [loaded, setLoaded] = useState(false);

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 1,
      spacing: 0,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 680px)': {
        slides: { perView: 2, spacing: 0 },
      },
      '(min-width: 960px)': {
        slides: { perView: 3, spacing: 0 },
      },
      '(min-width: 1200px)': {
        slides: { perView: 4, spacing: 0 },
      },
    },
  });

  return (
    <section className={styles.section}>
      <h2 className={styles.title}> You might like these too</h2>
      <div className={`${styles.carouselWrapper}`}>
        {loaded && !!instanceRef.current && (
          <ul className={styles.actionsNav}>
            <li>
              <Button
                shape="circle"
                className={`${styles.itemNavArrow} ${styles.itemNavArrowLeft}`}
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                icon={<LeftOutlined />}
              />
            </li>
            <li>
              <Button
                shape="circle"
                className={`${styles.itemNavArrow} ${styles.itemNavArrowRight}`}
                icon={<RightOutlined />}
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
              />
            </li>
          </ul>
        )}
        <div ref={ref} className="keen-slider">
          {posts?.map((item) => (
            <div className="keen-slider__slide" key={item.id}>
              <BlogCardItem item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { SimilarPosts };
