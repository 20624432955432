export function convertTimeMinutesText(time: number) {
  if (time < 0.5) {
    return 'less than a min';
  }

  if (time >= 0.5 && time < 1.5) {
    return '1 min read';
  }

  return `${Math.ceil(time)} min read`;
}

export function convertTimeToMinutes(time: number) {
  if (time < 0.5) {
    return 0.5;
  }

  if (time >= 0.5 && time < 1.5) {
    return 1;
  }

  return Math.ceil(time);
}

export function stripTags(text: string) {
  const pattern = '<\\w+(\\s+("[^"]*"|\\\'[^\\\']*\'|[^>])+)?>|<\\/\\w+>';
  const reg = new RegExp(pattern, 'gi');

  return text.replace(reg, '');
}

export function stripWhitespace(text: string) {
  if (!text) return 'text';

  return text.replace(/^\s+/, '').replace(/\s+$/, '');
}

const WORDS_PER_MIN = 275;
const CHINESE_KOREAN_READ_TIME = 500;

export function countWords(text: string) {
  const pattern = '\\w+';
  const reg = new RegExp(pattern, 'g');

  return (text.match(reg) || []).length;
}

// Chinese / Japanese / Korean
export function readOtherLanguages(text: string) {
  const pattern = '[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]';
  const reg = new RegExp(pattern, 'g');
  const count = (text.match(reg) || []).length;
  const time = count / CHINESE_KOREAN_READ_TIME;
  const formattedText = text.replace(reg, '');

  return {
    count,
    time,
    formattedText,
  };
}

export function readWords(text: string, wordsPerMin = WORDS_PER_MIN) {
  const { count: characters, time: otherLanguageTime, formattedText } = readOtherLanguages(text);
  const words = countWords(formattedText);
  const time = words / wordsPerMin;

  return {
    characters,
    otherLanguageTime,
    words,
    time,
  };
}
