import { FC } from 'react';
import styles from './Jumbtron.module.scss';
import { JumbtronProps } from './Jumbtron.props';

const Jumbtron: FC<JumbtronProps> = ({ title, subtitle }) => (
  <div className={styles.jumbtron}>
    <h1 className={styles.title}>{title}</h1>
    {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}
  </div>
);

export { Jumbtron };
